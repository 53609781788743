import { FC, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
// components
import ApplicationTab from "../../detailView/tabs/ApplicationTab";
import CreditEducationDetail from "../salesTabs/CreditEducationForm/CreditEducationDetail";
import SaleMgmtTab from "../salesTabs/SaleMgmtTab/SaleManagement";
import { Verification } from "../salesTabs/verification/Verification";
import { VehicleAndTempTag } from "../salesTabs/vehicleAndTempTag/VehicleAndTempTag";
import { Terms } from "../salesTabs/terms/Terms";
import { SalesDocuments } from "../salesTabs/salesDocuments/SalesDocuments";
import { SalesTax } from "../salesTabs/terms/salesTax/SalesTax";
import { TradeIn } from "../salesTabs/tradeIn/TradeIn";
import { CryptoSign } from "../salesTabs/cryptoSign/CryptoSign";
import { SecureClose } from "../salesTabs/secureClose/SecureClose";
import CreditReports from "../salesTabs/creditReports/CreditReports";
// state
import { useAppDispatch } from "@/store/store";
import { salesActions } from "../../salesSlice";
import {
  getApplication,
  getAvailableVehicles,
  getCompanyUnderwriting,
  getLienholders,
  getSaleData,
} from "../../salesActionCreator";

/** ###  */
const SaleDetailsRouter: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const newSale = params.id === "newSale";
  const appRecId = Number(params.id);

  useEffect(() => {
    dispatch(salesActions.reset());
    if (newSale) {
      dispatch(salesActions.setSaleData({}));
    } else {
      dispatch(getSaleData(appRecId)).then((res) => {
        dispatch(getCompanyUnderwriting(res.sale!.compid!));
        dispatch(getAvailableVehicles(res.sale!.compid!));
        dispatch(getLienholders(res.sale!.compid!));
      });
      dispatch(getApplication(appRecId));
    }
  }, [appRecId]);

  return (
    <Routes>
      <Route path="sale-management/*" element={<SaleMgmtTab />} />
      {/* @todo convert to snake-case */}
      <Route path="creditReports" element={<CreditReports />} />
      <Route path="application" element={<ApplicationTab />} />
      <Route path="verification" element={<Verification />} />
      {/* @todo convert to snake-case */}
      <Route path="vehicleAndTempTag" element={<VehicleAndTempTag />} />
      {/* @todo convert to snake-case */}
      <Route path="creditEducation/*">
        <Route path="buyer" element={<CreditEducationDetail isCobuyer={false} />} />
        <Route path="cobuyer" element={<CreditEducationDetail isCobuyer={true} />} />
        <Route path="*" element={<Navigate to="./buyer" relative="path" replace={true} />} />
      </Route>
      <Route path="terms/*">
        <Route path="" element={<Terms />} />
        <Route path="sales-tax" element={<SalesTax />} />
        <Route path="trade-in" element={<TradeIn />} />
        <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
      </Route>
      <Route path="documents/*">
        <Route path="" element={<SalesDocuments />} />
        <Route path="secureclose" element={<SecureClose />} />
        <Route path="cryptosign" element={<CryptoSign />} />
        <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
      </Route>
      <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />

      {/* Fallback */}
      {/* ex. 1 */}
      <Route path="*" element={<Navigate to="sale-management" relative="path" replace={true} />} />
      <Route path="/" element={<Navigate to="sale-management" relative="path" replace={true} />} />
    </Routes>
  );
};

export default SaleDetailsRouter;
