import { toast } from 'react-toastify';
// utils
import { AxiosService } from '@/services/axiosService';
import { store } from '@/store/store';
// interfaces
import { Company, ICompanyLookup } from '@/interfaces/Company';
import { ApiResponse } from '@/interfaces/Api';
import { SupportUserData } from '@/interfaces/Support';
import { CpiSettings } from '@/interfaces/applications';
import { UnreadSMS } from '@/features/notifications/interfaces';
import { CreateSupportPostReq } from '@/features/RootView/SupportWidget/interfaces';

class CompanyService extends AxiosService {
  public constructor() {
    super();
  }

  async getUnreadSMS(locId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<UnreadSMS[]>>('/Company/Messages', {
        params: { locId },
      });

      // We only need the most recent message per phone number
      const sortedSms = data.data!.sort((a, b) => {
        return b.recId - a.recId;
      });

      const unreadSmsMap: { [key: string]: UnreadSMS } = {};

      sortedSms.forEach((sms) => {
        const existingSMS = unreadSmsMap[sms.phoneNumber];
        if (existingSMS) {
          existingSMS.unreadCount = existingSMS.unreadCount ? existingSMS.unreadCount + 1 : 1;
        } else {
          unreadSmsMap[sms.phoneNumber] = { unreadCount: 1, ...sms };
        }
      });

      return Object.values(unreadSmsMap);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCompanyDetails(recId: number) {
    try {
      const data = await this.axios.get<{ data: Company }>('/Company/GetCompanyInformation', {
        params: { recId },
      });
      return data.data.data;
    } catch (e) {
      console.error(e);
      toast.error('There was an issue fetching the Company information.');
      throw e;
    }
  }

  async getCompanies() {
    try {
      const res = await this.axios.get<ApiResponse<ICompanyLookup[]>>(
        '/Company/GetCompaniesByDefOrgId'
      );

      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error('Unable to fetch companies');
      throw e;
    }
  }

  async createSupportRequest(payload: CreateSupportPostReq) {
    try {
      await this.axios.post('Support/CreateSupportRequest', payload);
    } catch (e) {
      console.error(e);
      toast.error('Error creating support request');
      throw e;
    }
  }

  async getSupportUserData() {
    try {
      const { data } = await this.axios.get<ApiResponse<SupportUserData>>('Support/UserData');
      return data.data!;
    } catch (e) {
      console.error(e);
      //intentionally not throwing error if 404
      return;
    }
  }

  async getCpiSettings(companyId?: number) {
    let compId;
    if (!companyId) {
      compId = store.getState().auth.compId;
    }
    compId = companyId;
    if (!compId) {
      console.error('No compId found in state');
      return;
    }

    try {
      const res = await this.axios.get<ApiResponse<CpiSettings>>('Company/GetCpiSettings', {
        params: { compId },
      });
      return res.data.data;
    } catch (e) {
      toast.error('Unable to load CPI settings');
      console.error(e);
      throw e;
    }
  }
}

export const companyService = new CompanyService();
