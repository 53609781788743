import React, { FC } from 'react';
import { TextField, Grid, Button, Typography, Switch, MenuItem } from '@mui/material';

const CompanyDefaults: FC = () => {
  return (
    <form style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Company Defaults
      </Typography>
      <Grid container spacing={3}>
        {/* General Defaults Section */}
        <Grid item xs={12}>
          <Typography variant="h6">General Defaults</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Type of Financing" select variant="outlined">
            <MenuItem value="retail">Retail</MenuItem>
            <MenuItem value="lease">Lease</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Switch />
          <Typography component="span">Use Tax on Storage</Typography>
        </Grid>

        {/* Default Terms Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Default Terms</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="APR" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Schedule" select variant="outlined">
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Term Months" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Base Payment" variant="outlined" />
        </Grid>

        {/* CP Defaults Section */}
        <Grid item xs={12}>
          <Typography variant="h6">CP Defaults</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Rebate Amount" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Monthly Fee" variant="outlined" />
        </Grid>

        {/* Lease Defaults Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Lease Defaults</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Disposition Fee" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Excess Mileage Cost" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Security Deposit" variant="outlined" />
        </Grid>

        {/* Final Buttons */}
        <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '20px' }}>
          <Grid item>
            <Button variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyDefaults;
