// @todo rename to main.tsx post-alpha
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
// components
import App from './App';
// utils
import { sentryOptions } from '@/utils/sentryCommon';
import { isDev } from '@/env';
// style
import '@/styles/index.scss';

Sentry.init({
  ...sentryOptions,
  environment: import.meta.env.MODE,
  enabled: !isDev,
});
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
