import { toast } from "react-toastify";
// validation
import { PmtFormCtx } from "../PmtFormProvider";
// services
import { accountsService } from "@/services/accountsService";
import { paymentService } from "@/services/paymentService";
// utils
import { pollForReceipt } from "@/utils/helpers/payment";

// @todo Adapt to handle all payment providers
export const submitPayment = async (pmtFormCtx: PmtFormCtx) => {
  const { setIsCliqModalOpen, isCcOrAch, formState } = pmtFormCtx;
  const isMiscPmt = formState.config.external.isMiscPmt;
  const reqBody = isMiscPmt
    ? pmtFormCtx.reqBodyValidationMisc.validation
    : pmtFormCtx.reqBodyValidation.validation;
  const form = pmtFormCtx.formState.form;

  if (form.provider === "Cliq" && isCcOrAch && !form.useSavedPaymentMethod)
    return setIsCliqModalOpen(true);

  if (!reqBody.data || reqBody.error) {
    console.warn(`Invalid request body:`, reqBody.error);
    return;
  }

  try {
    const res = await paymentService.submitCliqPayment(reqBody.data);
    if (res.success && res.paymentRecId) {
      const url = await paymentService.getReceiptUrl(res.paymentRecId);
      pmtFormCtx.setReceiptUrl(url);
    }
  } catch {
    pmtFormCtx.setIsSubmitting(false);
  }
};

/** @deprecated simplify and migrate all logic to `submitPayment` */
export const submitPaymentDeprec = async (pmtFormCtx: PmtFormCtx) => {
  const { setShowPendingRewriteModal, formState, continueSubmitting } = pmtFormCtx;
  const isMiscPmt = formState.config.external.isMiscPmt;
  const reqBody = isMiscPmt
    ? pmtFormCtx.reqBodyValidationMisc.validation
    : pmtFormCtx.reqBodyValidation.validation;
  const form = pmtFormCtx.formState.form;
  const maxPayment = pmtFormCtx.formState.config.external.maxPayment;

  if (form.provider === "Cliq") {
    await submitPayment(pmtFormCtx);
    throw null;
  }
  if (!reqBody?.data) {
    console.warn(`Invalid request body:`, reqBody.error);
    throw new Error("Invalid form");
  }

  if (!isMiscPmt) {
    // @todo remove conditional - should be handled by form state
    if (!formState.config.external.isPrincipalOnly && form.totalPayment < 0) {
      toast.error("Total payment cannot be less than 0");
      return; // @todo change to `throw new Error(...)`
    }
    // @todo remove conditional - should be handled by form state
    if (!formState.config.external.isPrincipalOnly && form.totalPayment > maxPayment) {
      toast.error("Total payment cannot be greater than max payment");
      return; // @todo change to `throw new Error(...)`
    }

    if (pmtFormCtx.reqBodyValidation.validation.data && !continueSubmitting) {
      try {
        // @todo pull from `pmtFormCtx.isPendingRewrite.value`
        const isPendingRewrite = await accountsService.getIsPendingRewrite(
          pmtFormCtx.reqBodyValidation.validation.data.appRecId
        );

        if (isPendingRewrite) {
          setShowPendingRewriteModal(true);
          return;
        }
      } catch (error) {
        pmtFormCtx.setIsSubmitting(false);
      }
    }
  }

  // @todo move to form config - this is a form-validation rule
  if (form.useSavedPaymentMethod && !form.mpd?.token) {
    // This is good enough for now, an inline error would be better
    // An inline error is just clunky to implement because the saved payment state is not contained in the form
    toast.error("Select a saved payment method or enter a new payment method");
    throw new Error("form.useSavedPaymentMethod && !mpd?.token: ", { cause: { form, reqBody } });
  }

  try {
    const res = await paymentService.submitPayment(reqBody.data);
    if (form.provider === "OpenEdge") await submitPaymentOpenEdge(pmtFormCtx, res);
    if (form.provider === "REPAY") await submitPaymentRepay(pmtFormCtx, res);
  } catch (e) {
    console.error(e, (e as Error).cause);
    pmtFormCtx.setIsSubmitting(false); // @note remove `pmtFormCtx.setIsSubmitting(false);` elsewhere - only needs to be called once
  }
};

const submitPaymentOpenEdge = async (pmtFormCtx: PmtFormCtx, res: ISubmitPmtOpenEdge) => {
  const { isCc, setPaymentLogRecId, setIsOpenEdgeModalOpen, formState } = pmtFormCtx;
  const isMiscPmt = formState.config.external.isMiscPmt;
  const reqBody = isMiscPmt
    ? pmtFormCtx.reqBodyValidationMisc.validation
    : pmtFormCtx.reqBodyValidation.validation;
  const form = pmtFormCtx.formState.form;

  res.paymentLogRecId && setPaymentLogRecId(res.paymentLogRecId);

  if (isCc && !form.useSavedPaymentMethod) {
    // Handle response errors
    // @todo Add type to response (in axios request)
    if (!res.paymentLogRecId) {
      toast.error("No PaymentLogRecId returned from res");
      throw new Error("No PaymentLogRecId returned from res", { cause: { form, reqBody, res } });
    }

    setIsOpenEdgeModalOpen(true);
  } else {
    if (res.paymentLogRecId) {
      await pollForReceipt(res.paymentLogRecId, pmtFormCtx.setReceiptUrl);
      pmtFormCtx.setIsSubmitting(false);
    }
  }
};

const submitPaymentRepay = async (pmtFormCtx: PmtFormCtx, res: ISubmitPmtRepay) => {
  const { setPaymentLogRecId, setRepayIframeUrl, formState } = pmtFormCtx;
  const isMiscPmt = formState.config.external.isMiscPmt;
  const reqBody = isMiscPmt
    ? pmtFormCtx.reqBodyValidationMisc.validation
    : pmtFormCtx.reqBodyValidation.validation;

  const form = pmtFormCtx.formState.form;
  const errMsgObj = { form, formValidation: formState.validation, reqBody, res };
  if (!res.success) {
    toast.error("Unable to load repay payment form");
    throw new Error("Unable to load repay payment form", { cause: errMsgObj });
  }
  if (res.paymentLogRecId === 0) {
    throw new Error("PaymentLogRecId cannot be 0", { cause: errMsgObj });
  }
  if (!res.paymentLogRecId) {
    const msg = "No PaymentLogRecId returned from res";
    toast.error(msg);
    throw new Error(msg, { cause: errMsgObj });
  }

  setPaymentLogRecId(res.paymentLogRecId);

  // This runs if paid-in = CC/ACH
  if (res.iFrameUrl) setRepayIframeUrl(res.iFrameUrl);
  // (i.e. "Cash")
  else await pollForReceipt(res.paymentLogRecId, pmtFormCtx.setReceiptUrl);

  pmtFormCtx.setIsSubmitting(false);

  // @note success-outcome handling happens in a `useEffect` within PmtFormProvider
};

type ISubmitPmtRepay = {
  success: boolean;
  creditCardLogId?: number | null;
  paymentLogRecId?: number | null;
  paymentRecId?: number | null;
  iFrameUrl?: string | null;
};
type ISubmitPmtOpenEdge = ISubmitPmtRepay;
