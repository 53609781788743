import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// Components
import { TextInput } from "@/components/inputs/text/TextInput";
import { PhoneInput } from "@/components/inputs/phoneInput/PhoneInput";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { Button } from "@/components/button/Button";
import CompanyDefaults from "./CompanyDefaults"; // Import the CompanyDefaults component
import { useCompanyMgmtCtx } from "./CompanyMgmtProvider"; // Import the context hook
import {Grid,Checkbox,FormControlLabel,Typography,} from "@mui/material"; // Import MUI components
import { Loader } from '@progress/kendo-react-all';

interface FormValues {
  companyId: string;
  companyName: string;
  shortName: string;
  legalName: string;
  abbreviation: string;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  phoneNumber: string;
  fax: string;
  federalId: string;
  dealerNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  lessorNumber: string;
  timeZone: string;
  adjustDaylightSavingsTime: boolean;
  companyLogo: FileList | null;

  // Ecom Setup Fields
  voicePhone: string;
  forwardCallsTo: string;
  emailFrom: string;
  emailDisplayName: string;
}

const CompanyDetails: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Tracks the currently selected tab

  // Access company details from context
  const { companyDetails, error } = useCompanyMgmtCtx((state) => ({
    companyDetails: state.companyDetails,
    error: state.error,
  }));

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    defaultValues: {
      companyId: "",
      companyName: "",
      shortName: "",
      legalName: "",
      abbreviation: "",
      addressLine1: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      phoneNumber: "",
      fax: "",
      federalId: "",
      dealerNumber: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      lessorNumber: "",
      timeZone: "",
      adjustDaylightSavingsTime: false,
      companyLogo: null,

      // Ecom Setup Fields
      voicePhone: "",
      forwardCallsTo: "",
      emailFrom: "",
      emailDisplayName: "",
    },
  });

  const companyLogoFile: FileList | null = watch("companyLogo");

  // Map company details to form fields when companyDetails change
  useEffect(() => {
    if (companyDetails) {
      reset({
        companyId: companyDetails.orgRecId?.toString() || "",
        companyName: companyDetails.companyName || "",
        shortName: companyDetails.shortName || "",
        legalName: companyDetails.legalName || "",
        abbreviation: companyDetails.abbreviation || "",
        addressLine1: companyDetails.address || "",
        city: companyDetails.city || "",
        state: companyDetails.state || "",
        zip: companyDetails.zip || "",
        county: companyDetails.county || "",
        phoneNumber: companyDetails.phone || "",
        fax: companyDetails.fax || "",
        federalId: companyDetails.federalId || "",
        dealerNumber: companyDetails.dealerNumber || "",
        contactName: companyDetails.contactName || "",
        contactEmail: companyDetails.contactEmail || "",
        contactPhone: companyDetails.contactPhone || "",
        lessorNumber: companyDetails.lessorNumber || "",
        timeZone: companyDetails.timeZone || "",
        adjustDaylightSavingsTime: companyDetails.dayLight ?? false,
        companyLogo: null,

        // Ecom Setup Fields
        voicePhone: companyDetails.voicePhone || "",
        forwardCallsTo: companyDetails.forwardCallsTo || "",
        emailFrom: companyDetails.contactEmail || "",
        emailDisplayName: companyDetails.contactName || "",
      });
    }
  }, [companyDetails, reset]);

  const onSubmit = (data: FormValues) => {
    console.log("Form Submitted:", data);
    // Handle form submission (e.g., send updated data to the server)
  };

  if (error) {
    return <div>Error loading company details: {error.message}</div>;
  }

  if (!companyDetails) {
    return <Loader />; // Use the existing loader component
  }

  return (
    <>
      {/* Tab Content */}
      {selectedTab === 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* General Information Section */}
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SectionHeader title="General Information" />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                {/* Left Column */}
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Controller
                        name="companyId"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Company ID" disabled {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Company Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="shortName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Short Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="legalName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Legal Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="abbreviation"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Abbreviation" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="addressLine1"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Address" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="City" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="State" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="zip"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Zip" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="county"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="County" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Phone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="fax"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Fax" {...field} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Right Column */}
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Controller
                        name="federalId"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Federal ID" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="dealerNumber"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Dealer Number" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="contactName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Contact Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="contactEmail"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Contact Email" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="contactPhone"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Contact Phone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="lessorNumber"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Lessor Number" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="timeZone"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Time Zone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="adjustDaylightSavingsTime"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                              />
                            }
                            label="Adjust Daylight Savings Time"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="companyLogo"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Typography variant="subtitle1">Company Logo</Typography>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => field.onChange(e.target.files)}
                            />
                            {/* Display a preview if a file is selected */}
                            {companyLogoFile && companyLogoFile.length > 0 && companyLogoFile[0] && (
                              <Grid item sx={{ mt: 2 }}>
                                <img
                                  src={URL.createObjectURL(companyLogoFile[0])}
                                  alt="Company Logo Preview"
                                  style={{ width: "100px" }}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Ecom Setup Section */}
          <Grid container direction="column" spacing={2} sx={{ mt: 4 }}>
            <Grid item>
              <SectionHeader title="Ecom Setup" />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Controller
                        name="voicePhone"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Voice/Text Phone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="forwardCallsTo"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Forward Calls To" {...field} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Controller
                        name="emailFrom"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Email From" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="emailDisplayName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Email Display Name" {...field} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Submit Button */}
          <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
            <Button label="Submit" buttonStyle={{ width: "140px" }} />
          </Grid>
        </form>
      )}

      {selectedTab === 1 && <CompanyDefaults />} {/* Company Defaults Tab */}
    </>
  );
};

export default CompanyDetails;
