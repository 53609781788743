import React, { FC } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { BORDER_GREY, DCS_BLUE } from "@/mui/theme/colors";

// Import existing components
import CompanyDetails from "./CompanyDetails";
import CompanyDefaults from "./CompanyDefaults";
import CompanyMgmtProvider from "./CompanyMgmtProvider";
// Define all tabs
const tabs = [
  { value: "company-details", label: "Company Details" },
  { value: "company-defaults", label: "Company Defaults" },
  { value: "leasing-rates", label: "Leasing Rates" },
  { value: "checklist-items", label: "Checklist Items" },
  { value: "tax-settings", label: "Tax Settings" },
  { value: "repo-companies", label: "Repo Companies" },
  { value: "lien-holders", label: "Lien Holders" },
  { value: "addons-salestax", label: "Addons/Sales Tax" },
];

const CompanyManagementRouter: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = tabs.find((tab) =>
    location.pathname.includes(tab.value)
  )?.value || tabs[0]?.value;

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`./${newValue}`);
  };

  return (
    <CompanyMgmtProvider>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{
            borderBottom: `1px solid ${BORDER_GREY}`,
            backgroundColor: "#f5f5f5",
            padding: "10px 20px",
          }}
        >
          <h3 style={{ fontWeight: 700, margin: 0, marginRight: "20px" }}>
            Company Management
          </h3>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="company-management-tabs"
            sx={{
              ".MuiTab-root": {
                textTransform: "none",
                fontSize: 14,
                fontWeight: 600,
                color: "#000",
                borderBottom: "2px solid transparent",
                padding: "10px 20px",
              },
              ".MuiTab-root.Mui-selected": {
                color: DCS_BLUE,
                borderBottom: `2px solid ${DCS_BLUE}`,
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                aria-controls={`tabpanel-${tab.value}`}
              />
            ))}
          </Tabs>
        </Grid>

        {/* Content Section */}
        <Grid container sx={{ padding: "20px" }}>
          <Routes>
            <Route index element={<Navigate to="company-details" replace />} />
            <Route path="company-details" element={<CompanyDetails />} />
            <Route path="company-defaults" element={<CompanyDefaults />} />

            {/* Placeholder Routes for Additional Tabs */}
            <Route path="leasing-rates" element={<div>Leasing Rates Placeholder</div>} />
            <Route path="checklist-items" element={<div>Checklist Items Placeholder</div>}/>
            <Route path="tax-settings" element={<div>Tax Settings Placeholder</div>} />
            <Route path="repo-companies" element={<div>Repo Companies Placeholder</div>} />
            <Route path="lien-holders" element={<div>Lien Holders Placeholder</div>} />
            <Route path="addons-salestax" element={<div>Addons/SalesTax Placeholder</div>} />

            {/* Fallback Route */}
            <Route path="*" element={<Navigate to="company-details" replace />} />
          </Routes>
        </Grid>
      </Grid>
    </CompanyMgmtProvider>
  );
};

export default CompanyManagementRouter;
