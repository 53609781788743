import React, { FC, PropsWithChildren, createContext, useState, useEffect } from "react";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { companyService } from "@/services/companyService";
import { ICompany } from "@/interfaces";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import { useAuthSelector } from "@/features/auth/authSlice";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { SelectedCompany, UserInfo } from "@/interfaces/System"; // Assuming UserInfo is defined here
import { Dayjs } from "dayjs";

const useCtxState = () => {
  const [companyDetails, setCompanyDetails] = useState<ICompany | null>(null);
  const [userInfoState, setUserInfoState] = useState<UserInfo | null>(null);
  const [error, setError] = useState<Error | null>(null);

  // Access userInfo from Auth Context
  const userInfo = useAuthCtx((state) => state.userInfo);

  // Mapping function to convert SelectedCompany to ICompany
  const mapSelectedCompanyToICompany = (selectedCompany: SelectedCompany<Dayjs>): ICompany => {
    return {
      recId: selectedCompany.compId, // Assuming compId can serve as recId
      orgRecId: selectedCompany.orgRecId,
      companyName: selectedCompany.companyName,
      companyLogo: selectedCompany.companyLogo,
      phone: selectedCompany.phone,
      phone800: selectedCompany.phone800,
      fax: selectedCompany.fax,
      address: selectedCompany.address,
      city: selectedCompany.city,
      state: selectedCompany.state,
      zip: selectedCompany.zip,
      minCreditCardAmount: selectedCompany.minCreditCardAmount,
      contactName: selectedCompany.contactName ?? null,
      contactEmail: selectedCompany.contactEmail ?? null,
      dayLight : selectedCompany.dayLight 
    };
  };

  useEffect(() => {
    if (userInfo) {
      setUserInfoState(userInfo);
      if (userInfo.selectedCompany) {
        const company = mapSelectedCompanyToICompany(userInfo.selectedCompany);
        setCompanyDetails(company);
      } else {
        setError(new Error("Company details are not available in userInfo."));
      }
    } else {
      setError(new Error("User information is not available."));
    }
  }, [userInfo]);

  return {
    companyDetails,
    userInfo: userInfoState,
    error,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type CompanyMgmtCtx = ICtx;
export const useCompanyMgmtCtx = useCtxFactory(Ctx);

const CompanyMgmtProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default CompanyMgmtProvider;
