// state
// interfaces
import { ValidationError } from "./interfaces";
import { toPairs } from "lodash";

const errorMapping: { [key: string]: { name: string; msg: string } } = {
  ApplicationId: { name: "Application Id", msg: "" },
  LoanType: { name: "Loan Type", msg: "" },
  RequestedFinancingAmount: { name: "Requested Financing Amount", msg: "" },
  RequestedTermInMonths: { name: "Requested Term In Months", msg: "" },
  LeasePaymentAmount: { name: "Lease Payment Amount", msg: "" },
  LeasePrice: { name: "Lease Price", msg: "" },
  GapFee: { name: "Gap Fee", msg: "" },
  WarrantyFee: { name: "Warranty Fee", msg: "" },
  AdminFee: { name: "Admin Fee", msg: "" },
  MaintenanceFee: { name: "Maintenance Fee", msg: "" },
  ServiceFee: { name: "Service Fee", msg: "" },
  CapCost: { name: "Cap Cost", msg: "" },
  AdjCapCost: { name: "Adj Cap Cost", msg: "" },
  ResidualRate: { name: "Residual Rate", msg: "" },
  ResidualAmount: { name: "Residual Amount", msg: "" },
  MoneyFactor: { name: "Money Factor", msg: "" },
  InterestRate: { name: "Interest Rate", msg: "" },
  TradeAllowance: { name: "Trade Allowance", msg: "" },
  TradePayoff: { name: "Trade Payoff", msg: "" },
  DeferredAmount: { name: "Deferred Amount", msg: "" },
  CashDownPayment: { name: "CashDown Payment", msg: "" },
  SalesTaxOnDownPayment: { name: "Sales Tax On Down Payment", msg: "" },
  LicenseAndRegistrationFee: { name: "License And Registration Fee", msg: "" },
  TitleFee: { name: "Title Fee", msg: "" },
  DocFee: { name: "Doc Fee", msg: "" },
  SecurityDeposit: { name: "Security Deposit", msg: "" },
  CollateralType: { name: "Collateral Type", msg: "" },
  VehicleMake: { name: "Vehicle Make", msg: "" },
  VehicleModel: { name: "Vehicle Model", msg: "" },
  VehicleMileage: { name: "Vehicle Mileage", msg: "" },
  VehicleYear: { name: "Vehicle Year", msg: "" },
  VehicleAgeInMonths: { name: "Vehicle Age In Months", msg: "" },
  Vin: { name: "Vin", msg: "" },
  CollateralPurchasePrice: { name: "Collateral Purchase Price", msg: "" },
  CollateralValueMmr: { name: "Collateral Value Mmr", msg: "" },
  TradeInCollateralValue: { name: "Trade In Collateral Value", msg: "" },
  FirstName: { name: "First Name", msg: "" },
  MiddleName: { name: "Middle Name", msg: "" },
  LastName: { name: "Last Name", msg: "" },
  Ssn: { name: "Ssn", msg: "" },
  Email: { name: "Email", msg: "" },
  CellPhone: { name: "Cell Phone", msg: "" },
  HomePhone: { name: "Home Phone", msg: "" },
  Dob: { name: "Dob", msg: "" },
  HouseNumber: { name: "House Number", msg: "" },
  Street: { name: "Street", msg: "" },
  State: { name: "State", msg: "" },
  City: { name: "City", msg: "" },
  ZipCode: { name: "Zip Code", msg: "" },
  CreditScore: { name: "Credit Score", msg: "" },
  OccupancyType: { name: "Occupancy Type", msg: "" },
  LengthOfResidencyInMonths: { name: "Length Of Residency In Months", msg: "" },
  HousingPaymentAmount: { name: "Housing Payment Amount", msg: "" },
  IncomeTotalMonthlyNet: { name: "Income Total Monthly Net", msg: "" },
  IncomeMonthlyDisposable: { name: "Income Monthly Disposable", msg: "" },
  ExpenseRentMortgage: { name: "Expense Rent Mortgage", msg: "" },
  ExpenseOtherAuto: { name: "Expense Other Auto", msg: "" },
  ExpenseAutoInsurance: { name: "Expense Auto Insurance", msg: "" },
  ExpenseHomePhone: { name: "Expense Home Phone", msg: "" },
  ExpenseCellPhone: { name: "Expense Cell Phone", msg: "" },
  ExpenseElectric: { name: "Expense Electric", msg: "" },
  ExpenseGas: { name: "Expense Gas", msg: "" },
  ExpenseCreditCards: { name: "Expense Credit Cards", msg: "" },
  ExpenseStudentLoans: { name: "Expense Student Loans", msg: "" },
  ExpenseAlimony: { name: "Expense Alimony", msg: "" },
  ExpenseMiscellaneous: { name: "Expense Miscellaneous", msg: "" },
  ExpenseTotal: { name: "Expense Total", msg: "" },
  EmployerName: { name: "Employer Name", msg: "" },
  JobName: { name: "Job Name", msg: "" },
  EmployerWorkPhone: { name: "Employer Work Phone", msg: "" },
  EmployerAddress: { name: "Employer Address", msg: "" },
  MonthsOnJob: { name: "Months On Job", msg: "" },
  JobStatus: { name: "Job Status", msg: "" },
  PaydayFrequency: { name: "Payday Frequency", msg: "" },
  GrossMonthlyIncome: { name: "Gross Monthly Income", msg: "" },
  TakeHomeMonthlyIncome: { name: "Take Home Monthly Income", msg: "" },
  OtherMonthlyIncome: { name: "Other Monthly Income", msg: "" },
};

export type ErrorMap = {
  ApplicationId?: string;
  LoanType?: string;
  RequestedFinancingAmount?: string;
  RequestedTermInMonths?: string;
  LeasePaymentAmount?: string;
  LeasePrice?: string;
  GapFee?: string;
  WarrantyFee?: string;
  AdminFee?: string;
  MaintenanceFee?: string;
  ServiceFee?: string;
  CapCost?: string;
  AdjCapCost?: string;
  ResidualRate?: string;
  ResidualAmount?: string;
  MoneyFactor?: string;
  InterestRate?: string;
  TradeAllowance?: string;
  TradePayoff?: string;
  DeferredAmount?: string;
  CashDownPayment?: string;
  SalesTaxOnDownPayment?: string;
  LicenseAndRegistrationFee?: string;
  TitleFee?: string;
  DocFee?: string;
  SecurityDeposit?: string;
  CollateralType?: string;
  VehicleMake?: string;
  VehicleModel?: string;
  VehicleMileage?: string;
  VehicleYear?: string;
  VehicleAgeInMonths?: string;
  Vin?: string;
  CollateralPurchasePrice?: string;
  CollateralValueMmr?: string;
  TradeInCollateralValue?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  Ssn?: string;
  Email?: string;
  CellPhone?: string;
  HomePhone?: string;
  Dob?: string;
  HouseNumber?: string;
  Street?: string;
  State?: string;
  City?: string;
  ZipCode?: string;
  CreditScore?: string;
  OccupancyType?: string;
  LengthOfResidencyInMonths?: string;
  HousingPaymentAmount?: string;
  IncomeTotalMonthlyNet?: string;
  IncomeMonthlyDisposable?: string;
  ExpenseRentMortgage?: string;
  ExpenseOtherAuto?: string;
  ExpenseAutoInsurance?: string;
  ExpenseHomePhone?: string;
  ExpenseCellPhone?: string;
  ExpenseElectric?: string;
  ExpenseGas?: string;
  ExpenseCreditCards?: string;
  ExpenseStudentLoans?: string;
  ExpenseAlimony?: string;
  ExpenseMiscellaneous?: string;
  ExpenseTotal?: string;
  EmployerName?: string;
  JobName?: string;
  EmployerWorkPhone?: string;
  EmployerAddress?: string;
  MonthsOnJob?: string;
  JobStatus?: string;
  PaydayFrequency?: string;
  GrossMonthlyIncome?: string;
  TakeHomeMonthlyIncome?: string;
  OtherMonthlyIncome?: string;
};


const createValidationErrors = (
  errors: ErrorMap, 
): ValidationError[] => {
  const validationErrors: ValidationError[] = [];
  const errorsArr = toPairs(errors);

  // Iterate through each error in the list
  errorsArr.forEach((error) => {
    const mappedValue = errorMapping[error[0]];

    // Ensure that mappedValue is defined before creating a ValidationError
    if (mappedValue) {
      const validationError = new ValidationError(
        error, // Use mapping msg as the main error message
        mappedValue.msg, // msg from the mapping object
        mappedValue.name, // label from the mapping object
      );
      validationErrors.push(validationError);
    }
    else {
      console.warn("Could not find", error);
    }
  });

  return validationErrors;
};

const submitValidationErrors = (errors: ErrorMap | null) => {
  if (errors === null) return null;

  // Use the updated createValidationErrors function
  const validationErrorList = createValidationErrors(errors);

  console.log("Generated Validation Errors:", validationErrorList);
  return validationErrorList.length > 0 ? validationErrorList : null;
};

export default submitValidationErrors;
