import { FC } from 'react';
import { Navigate, Outlet, Route, Routes, useMatch } from 'react-router-dom';
// mui
import ViewLayout from '@/mui/components/layout/ViewLayout';
// components
import UserAuthTable from './AuthorizationMgmt/UserAuthTable';
import UserAuthDetail from './AuthorizationMgmt/UserAuthDetail';
import AuthRolesTable from './AuthorizationMgmt/AuthRolesTable';
import AuthRoleDetail from './AuthorizationMgmt/AuthRoleDetail';
import UserMgmtViewToggleButton from './UserMgmtViewToggleButton';
// state
import SettingsViewProvider from './AuthSettingsViewProvider';
// utils
import FeatureFlag from '@/env/FeatureFlag';
import useEmailFeatureFlag from '@/env/useEmailFeatureFlag';
import useNavUpElem from '@/utils/routing/useNavUpElem';
import CompanyManagementRouter from './CompanyManagement/CompanyManagementRouter';

const SettingsLayout: FC = () => {
  const match = useMatch({ path: '/:root/:viewRoot/:currentView/*' });
  const detailMatch = useMatch({ path: '/:root/:viewRoot/:currentView/:detailView' });

  const currentView = match?.params.currentView;
  const isUsersView = currentView === 'users';
  const isCompanyManagementView = currentView === 'company-management';
  const isCreateUserView =
    detailMatch?.params.currentView === 'users' && detailMatch?.params.detailView === 'new';

  const secondary = isUsersView
    ? 'Users'
    : isCompanyManagementView
    ? 'Company Management'
    : 'Roles';

  return (
    useNavUpElem(isCreateUserView) || (
      <ViewLayout
        headerProps={{
          primaryText: 'Settings and Security',
          secondaryText: secondary,
          viewSelector: isUsersView ? <UserMgmtViewToggleButton /> : undefined, // Toggle only for users
        }}
      >
        <Outlet />
      </ViewLayout>
    )
  );
};

/** ### `Settings` view
 *
 * 1. Company Setting
 * 1. User Management
 *     - "Users" list view
 *     - "User" detail view
 *     - "Roles" list view
 *     - "Role" detail view
 * 1. Ecom Template
 * 1. Account Settings - goes to Identity Server
 * 1. DCS Support Control Center
 */
const SettingsRouter: FC = () => {
  return (
    <Routes>
      <Route Component={SettingsLayout}>
        {/* User Management Routes */}
        <Route path="user-management/*">
          <Route path="users" element={<UserAuthTable />} />
          <Route path="users/:userRecId" element={<UserAuthDetail />} />
          <Route path="roles" element={<AuthRolesTable />} />
          <Route path="roles/:roleRecId" element={<AuthRoleDetail />} />

          <Route path="*" element={<Navigate to="users" replace />} />
        </Route>

        {/* Company Management Routes */}
        <Route path="company-management/*" element={<CompanyManagementRouter />} />
        {/* Fallback for all other unmatched settings routes */}
        <Route path="*" element={<Navigate to="user-management/users" replace />} />
      </Route>
    </Routes>
  );
};

const Settings: FC = () => {
  const emailFeatureFlag = useEmailFeatureFlag();

  return (
    <FeatureFlag maxAllowedEnv="qa" conditions={[emailFeatureFlag]}>
      <SettingsViewProvider>
        <SettingsRouter />
      </SettingsViewProvider>
    </FeatureFlag>
  );
};

export default Settings;
