import { SalesSubview } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  ApplicationsFilterKey,
  ApplicationsSortKey,
  appStatusFilterMap,
  applicationsFilterFields,
  applicationsSortFields,
} from './dataModelConfig';
import { ApplicationsDb } from './interfaces';
import { applicationsTableColumns } from './tableColumns';

export const applicationsSubviewConfig: SubviewInfo<ApplicationsSortKey, ApplicationsFilterKey> = {
  text: 'Applications',
  route: SalesSubview.enum.applications,
  columns: applicationsTableColumns,
  initSortField: 'saleDate',
  categoryFilter: { field: 'appStatus', label: 'App Status', options: appStatusFilterMap },
  sortableFields: applicationsSortFields,
  filterableFields: applicationsFilterFields,
  dbRowValidationFxn: (row: ApplicationsDb) => ApplicationsDb.new(row),
};
